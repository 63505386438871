<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-31 03:22:25
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:58
 * @Description: 通知管理
 * @FilePath: \src\views\Frame\Notify\NotifyManage.vue
-->
<template>
  <div class="vue-box">
    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="success"
        icon="el-icon-plus"
        @click="_OpenNewNotifyModel"
        >创建通知消息</el-button
      >
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="_Load()"
        >重载数据</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="notify_id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 通知标题 -->
      <el-table-column
        label="通知标题"
        prop="name"
        align="center"
        width="120px"
      ></el-table-column>

      <!-- 消息类型 -->
      <el-table-column label="消息类型" align="center" width="70px">
        <template slot-scope="s">
          <el-tag size="small" effect="plain" type="info">{{
            s.row.type
          }}</el-tag>
        </template>
      </el-table-column>

      <!-- 摘要 -->
      <el-table-column label="摘要">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 创建日期 -->
      <el-table-column label="创建日期" align="center" width="135px">
        <template slot-scope="s"
          ><span>{{ s.row.add_time }}</span></template
        >
      </el-table-column>

      <!-- 失效日期 -->
      <el-table-column label="失效日期" align="center" width="135px">
        <template slot-scope="s"
          ><span>{{ s.row.add_time }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" width="240px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateNotifyModel(s.row.notify_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateNotifyModel(s.row.notify_id)"
            >接收详情</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelNotify(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-notify ref="nu-notify"></nu-notify>
  </div>
</template>

<script>
import { getNotifyContentTable, delNotify } from "./api"; //页面专有接口
import NuNotify from "./NUNotify";
export default {
  name: "NotifyManage", //对应路由地址
  components: {
    NuNotify,
  },
  data() {
    return {
      p: {
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  methods: {
    //载入数据
    _Load() {
      getNotifyContentTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
      })
        .then((res) => {
          this.TableData = res.data.data; // 重写数据
          this.p.dataCount = res.data.dataCount; // 重写数据行数
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 120005) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开消息通知创建组件
    _OpenNewNotifyModel() {
      this.$refs["nu-notify"].open(0);
    },

    //打开消息通知修改组件
    _OpenUpdateNotifyModel(id) {
      this.$refs["nu-notify"].open(id);
    },

    /**
     * 删除消息通知
     */
    _DelNotify(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除通知【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delNotify({
            notify_id: data.notify_id,
          })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
  created() {
    this._Load();
  },
};
</script>
